<script>
import { mapState } from 'vuex';
import nunjucks from 'nunjucks';
import { codemirror } from 'vue-codemirror';
import Dexie from 'dexie';

import 'codemirror/lib/codemirror.css';
import 'codemirror/theme/base16-dark.css';
import 'codemirror/mode/jinja2/jinja2';
import 'codemirror/mode/htmlmixed/htmlmixed';

import filters, { getExclusions } from '@/lib/agreement/filters';
import { BIconPencil, BIconQuestion } from 'bootstrap-vue';

import { functions } from '../firebase';

// Configure nunjucks
const env = nunjucks.configure({
	autoescape: false,
	throwOnUndefined: true,
	dev: true,
});

env.addGlobal('getVars', function () {
	return this.getVariables();
});

env.addGlobal('getContext', function () {
	return this.ctx;
});

env.addFilter('prettyPrint', function (string) {
	return JSON.stringify(string, null, 2);
});

for (let [key, value] of Object.entries(filters)) {
	env.addFilter(key, value);
}

const debugggingTips = [
	{
		command: '<pre><code>{{ getVars() | dump }}</code></pre>',
		description: 'Gets all of the data and variables.',
	},
	{
		command: '<pre><code>{{ getVars() | prettyPrint }}</code></pre>',
		description:
			'Gets all of the data and variables and pretty prints the data.',
	},

	{
		command: '<pre><code>{{ getContext() | dump | safe }}</code></pre>',
		description:
			'Gets the context of the current loop or entire context and outputs.',
	},
	{
		command: '<pre><code>{{ getContext() | prettyPrint }}</code></pre>',
		description:
			'Gets the context of the current loop or entire context and pretty prints the data.',
	},

	{
		command:
			'<pre><code>{{ getContext().properties | prettyPrint }}</code></pre>',
		description:
			'Gets the context of the current loop or entire context and then specifies which variable and pretty prints it.',
	},
	{
		command:
			'<pre><code>{{ properties | prettyPrint | safe }}</code></pre>',
		description: 'Take any variable or data and pretty prints it.',
	},
];

const macroList = [
	{
		command: 'runs',
		description:
			'This is used inside the listItem macro to handle italics.',
	},
	{ command: 'fullAddress', description: 'Streed, City, Province' },
	{ command: 'address', description: 'Just the Sreed property of address.' },
	{
		command: 'getAccNum',
		description: 'Gets the accountNumber given an object or liability.',
	},
	{ command: 'blankListItem', description: 'DEPRECATE? Does not look used' },
	{ command: 'listItem', description: 'Wraps the content in an li tag.' },
	{
		command: 'bulletAndList',
		description:
			'Represents a list with commas and an and before the last item. E.g. one, two, and three',
	},
	{
		command: 'listAdditionalClauses',
		description: 'Lists out all of additional clauses given a list.',
	},
	{ command: 'accountList', description: '' },
	{ command: 'accountsPartner', description: '' },
	{ command: 'accountsJointTransfer', description: '' },
	{ command: 'accountsKeep', description: '' },
	{ command: 'accountsClose', description: '' },
];

const filterList = [
	{
		command: 'anAPrefix',
		description:
			'Given a work or phrase returns the Prefixe of either an or a. ',
	},
	{ command: 'andList', description: '' },
	{ command: 'castId', description: '' },
	{ command: 'castOtherId', description: '' },
	{
		command: 'childOrChildren',
		description:
			'Given a list of children returns either `child` or `Children`.',
	},
	{
		command: 'childrenNames',
		description:
			'Given a list of children lists their preferred names in order of age.',
	},
	{
		command: 'childrenUnder19',
		description: 'Given a list of Children returns all who are under 19.',
	},
	{
		command: 'childrenOver19',
		description:
			'Given a list of Children returns all who are over 19 and not self supporting.',
	},
	{
		command: 'childrenSelfSupporting',
		description:
			'Given a list of Children returns all children who are self supporting.',
	},
	{ command: 'contentToRuns', description: '' },
	{ command: 'condenseWhitespace', description: '' },
	{ command: 'datePlusDays', description: '' },
	{ command: 'dateToAge' },
	{ command: 'getPartnerById' },
	{ command: 'getPartnerPreferredNameOrPassthru' },

	{ command: 'getOtherPartnerName' },
	{ command: 'getOtherPartnerById' },
	{ command: 'getPartnerName' },
	{ command: 'getExclusions' },
	{ command: 'getPronoun' },
	{ command: 'preferredName' },
	{ command: 'over19ChildSupport' },
	{ command: 'preferredNameOrChildren' },
	{ command: 'filterattr' },
	{ command: 'rejectattr' },
	{ command: 'firstLastName' },
	{ command: 'formatCurrency' },
	{ command: 'formatPercent' },
	{ command: 'formatDateMDY' },
	{ command: 'fullName' },
	{ command: 'getattrs' },
	{ command: 'getPartnerObjectById' },
	{ command: 'legalNumber' },
	{ command: 'missing' },
	{ command: 'orList' },
	{ command: 'preferredNames' },
	{ command: 'preferredPronoun' },
	{ command: 'uniqueShareholders' },
	{ command: 'makeListLowercase' },
	{ command: 'listAccountDescriptions' },
	{ command: 'smartSum' },
	{ command: 'getAccountNumber' },
	{
		command: 'childrenabove18',
		description:
			'Given a list of Children returns all who are over 19.',
	}
];

const macros = `
	{% macro runs(content) %}
		{% set runs = content | condenseWhitespace | contentToRuns -%}
		{% for run in runs %}
			{% if run.italic %}
			<i>
			{% endif %}
			{{ run.text | missing }}
			{% if run.italic %}
			</i>
			{% endif %}
		{% endfor -%}
		{% endmacro -%}


		{% macro fullAddress(address) -%}
		{{ address.street }},
		{{ address.city }},
		{{ address.province -}}
		{# {{ address.street }} #}
		{% endmacro -%}

		{% macro address(address) -%}
		{{ address.street -}}
		{% endmacro -%}

		{% macro blankListItem(level = 1) %}
		<li></li>
		{% endmacro -%}

		{% macro listItem(content = caller(), style = "TextBody", level = 1) %}
		<li>{{ runs(content) -}}</li>
		{% endmacro -%}

		{% macro bulletAndList(list, bulletLevel) %}
		{% for item in list -%}
			{% set content %}
			{{item| missing }}
			{%- if loop.last %}.{% elif loop.length > 1 and loop.revindex === 2 -%}; and {%- elif loop.length > 1 and loop.revindex > 2 -%}; {%- endif -%}
			{% endset %}

			{{ listItem(level=bulletLevel, content=content)| missing }}
		{%- endfor -%}
		{% endmacro -%}

		{% macro listAdditionalClauses(additionalClauses) %}
		{% for clause in additionalClauses %}
			{% call listItem(level = 6) %}
				{{ clause.text | missing }}
			{% endcall %}
		{% endfor %}
		{% endmacro %}

		{% set partner1Name %}{{partner1 | preferredName| missing }}{% endset %}
		{% set partner2Name %}{{partner2 | preferredName| missing }}{% endset %}

		{% set partnerAndPartner %}{{partner1Name| missing }}
		and
		{{partner2Name| missing }}{% endset %}

		{% set under19 = children | childrenUnder19 %}
		{% set over19 = children | childrenOver19 %}
		{% set selfSupporting = children | childrenSelfSupporting %}
		{% set childrenAndNotSelfSupporting = children | childrenNotSelfSupporting %}
		{% set childrenWithTableAmount = over19 | over19ChildSupport('table-amount') %}
		{% set childrenWithSpecialExpenses = over19 | over19ChildSupport('special-extraordinary-expenses') %}

		{% set childOrTheChildren %}
		{% if under19.length === 1 %}
			{{under19 | first | preferredName | missing -}}
		{% elif under19.length > 1 %}
			{{"the Children"}}
		{%- endif -%}
		{% endset %}

		{% set childSupportChildren %}
		{% if under19.length > 0 %}
			{% if over19.length > 1 %}
			, {{over19 | childrenNames | andList }}
			{% elif over19.length == 1 %}
			and {{over19 | first | preferredName }}
			{% endif %}
		{% else %}
			{% if over19.length > 0 %}
			{{over19 | childrenNames | andList }}
			{% elif over19.length == 1 %}
			{{over19 | first | preferredName }}
			{% endif %}
		{% endif %}
		{% endset %}

		{% set tableSupportChildren %}
		{{- "," + childrenWithTableAmount | childrenNames | andList if childrenWithTableAmount.length > 1 -}}
		{{" and " + childrenWithTableAmount | first | preferredName if childrenWithTableAmount.length == 1 -}}
		{% endset %}

		{% set expenseSupportChildren %}
		{{- "," + childrenWithSpecialExpenses | childrenNames | andList if childrenWithSpecialExpenses.length > 1 -}}
		{% if under19.length >= 1 -%}
			{{" and " + childrenWithSpecialExpenses  | first | preferredName if childrenWithSpecialExpenses.length == 1 -}}
		{% else %}
			{{childrenWithSpecialExpenses  | first | preferredName if childrenWithSpecialExpenses.length == 1 }}
		{% endif -%}
		{% endset %}

		{% set marriageOrRelationship %}
		{% if relationship.status === "Married" %}marriage{% else %}relationship{% endif %}{% endset %}
		{% set childrenIsAre = under19 | isAre %}

		{% set equalization = _ | propertyEqualization %}
		{% set registeredEqualization = _ | registeredPropertyEqualization %}

		{% set equalizationPaymentAmount %}
		{% if equalizationPayment.useDiviiAmount %}
			{{ equalization.amount | formatCurrency | missing }}
		{% else %}
			{{ equalizationPayment.amount | formatCurrency | missing }}
		{% endif %}
	{% endset %}`;

// Initialize index db
const db = new Dexie('divii');

// Declare tables, IDs and indexes
db.version(1).stores({
	code: '++id, name, code',
});

export default {
	name: 'Admin',
	components: {
		codemirror,
		BIconPencil,
		BIconQuestion,
	},
	computed: {
		...mapState(['agreement']),
		options() {
			return {
				tabSize: 4,
				theme: 'base16-dark',
				lineNumbers: true,
				line: true,
				mode: this.mode,
			};
		},
	},
	data() {
		return {
			preview: '',
			code: `<ol class="seven">
  <li>{{partner1.name.first | missing}} {{relationship.status | missing}} {{partner2.name.first | missing}}</li>
  <ol class="eight">
    <li>Another Clause</li>
  </ol>
</ol>`,
			mode: 'jinja2',
			error: '',
			savedCode: [],
			name: '',
			showHelp: false,
			macroList,
			filterList,
			debugggingTips,
			filtersVisible: false,
			debugTipsVisible: false,
			macrosVisible: false,
		};
	},
	mounted() {
		this.loadAgreementData();
		this.loadSavedCode();
	},
	methods: {
		async scrapeTablesButton() {
			const scrapeTables =
				functions.httpsCallable('scrapeTables');
			try {
				await scrapeTables();
			} catch (error) {
				console.error(error);
			}
		},
		loadAgreementData() {
			this.$store.dispatch('fetchAgreement', {
				id: this.$route.params.id,
			});
		},
		async loadSavedCode() {
			try {
				this.savedCode = await db.code.toArray();
			} catch (error) {
				console.warn(error);
			}
		},
		compile() {
			this.error = '';
			try {
				this.preview = nunjucks.renderString(`${macros} ${this.code}`, {
					...this.agreement,
					getExclusions,
				});
			} catch (error) {
				this.error = error;
				console.warn(error);
			}
		},
		changeMode(mode) {
			this.mode = mode;
		},
		async save() {
			try {
				await db.code.add({
					name: this.name,
					code: this.code,
				});
				this.loadSavedCode();
			} catch (error) {
				console.warn(error);
			}
		},
		editItem(editId) {
			this.code = this.savedCode.find(({ id }) => id == editId).code;
		},
		removeItem() {},
		toggleHelp() {
			this.showHelp = !this.showHelp;
		},
		async migratePermissions() {
			const migrateAgreements =
				functions.httpsCallable('migrateAgreements');

			try {
				const {
					data: { success },
				} = await migrateAgreements();
				// console.log(success);
			} catch (error) {
				console.error(error);
			}
		},
	},
};
</script>

<style>
@import '../assets/css/printstyles.css';

.missing {
	background: yellow;
}
</style>

<template>
	<b-container fluid>
		<b-sidebar
			id="sidebar-right"
			title="Divii Docs"
			right
			shadow
			:visible="showHelp"
		>
			<div class="px-3 py-2">
				<p>
					This is a short how to of functions, macros and filters for
					writing clauses in Divii.
				</p>
				<small class="mb-1">
					Equalization payment names won't show up here because they
					are pulled from the property schedule.
				</small>
				<h5
					style="cursor: pointer"
					@click="debugTipsVisible = !debugTipsVisible"
				>
					Debugging Tips:
				</h5>
				<b-collapse
					id="collapse-debugging-tips"
					class="mt-2"
					:visible="debugTipsVisible"
				>
					<b-list-group
						v-for="tip in debugggingTips"
						:key="tip.command"
					>
						<b-list-group-item>
							<p class="mb-1">{{ tip.command }}</p>
							<small v-if="tip.description">{{
								tip.description
							}}</small>
						</b-list-group-item>
					</b-list-group>
				</b-collapse>
				<h5
					style="cursor: pointer"
					@click="filtersVisible = !filtersVisible"
				>
					Filter List:
				</h5>
				<b-collapse
					id="collapse-filters"
					class="mt-2"
					:visible="filtersVisible"
				>
					<p>
						For built in filters check out their
						<b-link
							href="https://mozilla.github.io/nunjucks/templating.html#builtin-filters"
							target="_blank"
							>documentation</b-link
						>
					</p>
					<b-list-group
						v-for="filter in filterList"
						:key="filter.command"
					>
						<b-list-group-item>
							<p class="mb-1">{{ filter.command }}</p>
							<small v-if="filter.description">{{
								filter.description
							}}</small>
						</b-list-group-item>
					</b-list-group>
				</b-collapse>
				<h5
					style="cursor: pointer"
					@click="macrosVisible = !macrosVisible"
				>
					Macro List:
				</h5>
				<b-collapse
					id="collapse-macros"
					:visible="macrosVisible"
					class="mt-2"
				>
					<b-list-group
						v-for="macro in macroList"
						:key="macro.command"
					>
						<b-list-group-item>
							<p class="mb-1">{{ macro.command }}</p>
							<small v-if="macro.description">{{
								macro.description
							}}</small>
						</b-list-group-item>
					</b-list-group>
				</b-collapse>
			</div>
		</b-sidebar>
		<codemirror v-model="code" :options="options"></codemirror>
		<b-row class="my-2">
			<b-col cols="10">
				<b-button-group class="mr-2">
					<b-button variant="primary" @click="compile"
						>Render</b-button
					>
					<b-button variant="primary" @click="loadAgreementData"
						>Reload</b-button
					>
					<b-button @click="toggleHelp"><b-icon-question /></b-button>
				</b-button-group>

				<b-button-group>
					<b-button
						:pressed="mode == 'htmlmixed'"
						@click="changeMode('htmlmixed')"
						>Html
					</b-button>

					<b-button
						:pressed="mode == 'jinja2'"
						@click="changeMode('jinja2')"
					>
						Nunjucks
					</b-button>
					<b-button @click="scrapeTablesButton"
						>Scrape Child Support Tables</b-button
					>
					<b-button @click="migratePermissions"
						>Migrate Don't push this</b-button
					>
				</b-button-group>
			</b-col>
			<b-col cols="2">
				<b-button variant="primary" class="pull-right" @click="save"
					>Save</b-button
				>
			</b-col>
		</b-row>
		<b-row>
			<b-col
				cols="9"
				style="height: calc(100vh - 450px); overflow: scroll"
			>
				<div v-if="error">{{ error }}</div>
				<div
					v-else-if="preview"
					style="background: lightgray"
					class="preview"
					v-html="preview"
				></div>

				<div v-else>
					Enter some html or nunjucks into the text editor about and
					click render.
				</div>
			</b-col>
			<b-col cols="3">
				<b-container fluid>
					<b-row>
						<b-col>
							<b-form-input
								v-model="name"
								placeholder="Name your code"
								class="mb-2"
							/>
						</b-col>
					</b-row>
					<b-row>
						<b-col
							cols="12"
							style="overflow: scroll; max-height: 320px"
						>
							<b-list-group
								v-for="code in savedCode"
								:key="code.name"
								input-class="form-control"
							>
								<b-list-group-item
									variant="light"
									href="#"
									style="overflow-wrap: break-word"
								>
									{{ code.name }}
									<b-button-group class="float-right">
										<b-button
											size="sm"
											variant="success"
											@click="editItem(code.id)"
											data-testid="edit-item"
										>
											<b-icon-pencil />
										</b-button>
										<!-- <b-button
											size="sm"
											@click="removeItem(code.id)"
											variant="danger"
										>
											<b-icon-trash />
										</b-button> -->
									</b-button-group>
								</b-list-group-item>
							</b-list-group>
						</b-col>
					</b-row>
				</b-container>
			</b-col>
		</b-row>
	</b-container>
</template>
